import 'babel-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
//import configureStore from './lib/configureStore';

import './style.scss';

//const store = configureStore();
export const init = (config) => {
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<App />);
}

window.visualization = {
  init: init
}