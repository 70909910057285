// const fs = require("fs")
// const input = fs.readFileSync("maze.txt", "utf8")

export async function runMaze(input, callback) {
    let startTime = Date.now()

    let startPoint = []
    let exitPoint = []
    var myMaze = []
    input.trim().split('\n').forEach((line, i) => {
        let row = line.split('');
        if(row[row.length - 1] === '\r') {
            row.pop();
        }
        myMaze[i] = row.map((c, j) => {
            if(c === 'X') {
                return 0;
            } else {
                if(c === 'S') {
                    startPoint = [i, j]
                } else if(c === 'E') {
                    exitPoint = [i, j]
                }
                return 1;
            }
        });
        // console.log(myMaze[i])
    })

    const ROW = myMaze.length
    const COL = myMaze[0].length
    callback({ state: 'maze', data: myMaze });
    callback({ state: 'startPoint', data: startPoint });
    callback({ state: 'exitPoint', data: exitPoint });

    await sleep(2000)
    
    // To store matrix cell coordinates
    class Point{
        constructor(x, y){
            this.x = x
            this.y = y
        }
    }
    
    // A data structure for queue used in BFS
    class queueNode{
        constructor(pt, dist){
            this.pt = pt // The coordinates of the cell
            this.dist = dist // Cell's distance from the source
        }
    }
    
    // Check whether given cell(row,col)
    // is a valid cell or not
    function isValid(row, col){
        return (row >= 0) && (row < ROW) &&
                    (col >= 0) && (col < COL)
    }
    
    // These arrays are used to get row and column
    // numbers of 4 neighbours of a given cell
    let rowNum = [-1, 0, 0, 1]
    let colNum = [0, -1, 1, 0]
    
    // Function to find the shortest path between
    // a given source cell to a destination cell.
    let visited;
    let milestone;
    async function BFS(mat, src, dest){
        
        // check source and destination cell
        // of the matrix have value 1
        if(mat[src.x][src.y] !==1 || mat[dest.x][dest.y] !== 1)
            return -1
        
        visited = new Array(ROW).fill(false).map(()=>new Array(COL).fill(false));
        milestone = new Array(ROW).fill(false).map(()=>new Array(COL).fill(false));
        
        
        // Mark the source cell as visited
        visited[src.x][src.y] = true
        
        // Create a queue for BFS
        let q = []
        
        // Distance of source cell is 0
        let s = new queueNode(src,0)
        q.push(s) // Enqueue source cell
        
        // Do a BFS starting from source cell
        while(q){
    
            let curr = q.shift() // Dequeue the front cell
            // If we have reached the destination cell,
            // we are done
            if(!curr) {
                return -1;
            }
            let pt = curr.pt
            if(pt.x === dest.x && pt.y === dest.y) {
                q = [];
                callback({ state: 'queue', data: q });
                return curr.dist
            }
            
            // Otherwise enqueue its adjacent cells
            for(let i=0;i<4;i++){
                let row = pt.x + rowNum[i]
                let col = pt.y + colNum[i]
                
                // if adjacent cell is valid, has path
                // and not visited yet, enqueue it.
                if (isValid(row,col) && mat[row][col] == 1 && !visited[row][col]){

                    visited[row][col] = true
                    let Adjcell = new queueNode(new Point(row,col),
                                        curr.dist+1)
                    q.push(Adjcell)
                    milestone[row][col] = curr.dist+1
                    await sleep(1)
                    callback({ state: 'queue', data: q });
                    callback({ state: 'milestone', data: milestone });
                }
            }
            // console.log(q)
        }
        // Return -1 if destination cannot be reached
        return -1
    }
    
    // Driver code



    
    // console.log(startPoint, exitPoint);

    // let src = new Pair(startPoint[0], startPoint[1]);
    // let dest = new Pair(exitPoint[0], exitPoint[1]);

    let source = new Point(startPoint[0], startPoint[1])
    let dest = new Point(exitPoint[0], exitPoint[1])
        
    let dist = await BFS(myMaze,source,dest)
    // let dist = findShortestPathLength(myMaze, src, dest);

    async function getPathFromMilestone() {
        let path = [];
        let x = exitPoint[0];
        let y = exitPoint[1];
        path.push([x, y]);
        let dist = milestone[x][y];
        while(dist > 1) {
            for(let i=0;i<4;i++){
                let row = x + rowNum[i]
                let col = y + colNum[i]
                if (isValid(row,col) && milestone[row][col] === dist - 1){
                    path.push([row, col]);
                    callback({ state: 'path', data: path });
                    x = row;
                    y = col;
                    dist = milestone[x][y];
                    // console.log(dist)
                    await sleep(1)
                    break;
                }
            }
            // console.log(dist)
        }
        
        // console.log(path);
        return path;
    }

    function printMazeWithSolvedPath(path) {
        let maze = myMaze.map(row => row.map(cell => cell == 1 ? ' ' : 'X'));
        path.forEach(([x, y]) => maze[x][y] = '•');
        maze[startPoint[0]][startPoint[1]] = 'S';
        maze[exitPoint[0]][exitPoint[1]] = 'E';
        maze.forEach(row => console.log(row.join('')));
    }
    printMazeWithSolvedPath(await getPathFromMilestone())

    if (dist !== -1) {
        console.log("Shortest Path is " + dist);
        callback({ state: 'result', data: "Shortest Path is " + dist });
    } else {
        console.log("Shortest Path doesn't exist");
        callback({ state: 'result', data: "Shortest Path doesn't exist" });
    }
    
    await sleep(500)
    callback({ state: 'finish', data: true });
    await sleep(1200)
    callback({ state: 'cleanUp', data: true });

    console.log("Time taken: ", Date.now() - startTime, "ms")
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}