// Dependencies
import React from 'react'
import { AnimateSharedLayout, AnimatePresence, motion } from 'framer-motion'

// Library
import { runMaze } from './maze.js'

// Components
import Maze from './components/maze'

class App extends React.Component {

	constructor(props) {
		super(props)
		this.showFile = this.showFile.bind(this)
		this.onStateChange = this.onStateChange.bind(this)
	}

	state = {
		urlPath: window.location.origin + '/maze.txt',
		fileContent: null,
		maze: [],
		startPoint: null,
		exitPoint: null,

		milestone: null,
		path: null,
		queue: null,

		finish: false,
		result: null,
		cleanUp: null
	}

	onStateChange = (obj) => {
		// console.log(obj)
		const { state, data } = obj
		this.setState({
			[state]: data,
		})
		
	}

	render() {
		const { 
			urlPath,
			fileContent,
			maze,
			startPoint,
			exitPoint,

			milestone,
			path,
			queue,
			finish,
			result,
			cleanUp
		} = this.state

		return <div className="App">
			<AnimateSharedLayout mode="wait">
				<AnimatePresence key={'main'}>
					{ !fileContent &&
						<div className='chooseFile' key="chooseFile">
							<input type="text" style={{
								display: 'block',
								width: '100%',
								border: '2px solid black',
								padding: '5px',
							}} placeholder="Enter url" onChange={(e) => this.setState({ urlPath: e.target.value })} value={urlPath} />
							<button onClick={this.loadFileFromUrl}>Load from url</button>
							<input id="inputChooseFile" type="file" onChange={(e) => this.showFile(e)} />
							<button onClick={()=>{
								document.getElementById('inputChooseFile').click()
							}}>Choose file</button>
						</div>
					}

					{fileContent && maze && <>
						<br/><br/>
						<motion.h1 style={{
							textAlign: 'center'
						}}
							initial={{
								opacity: 0,
								scale: 0.5
							}}
							animate={{
								opacity: 1,
								scale: 1
							}}
							transition={{ duration: 0.4 }}
						>BFS / Size: {maze.length}x{maze[0].length}</motion.h1>
						<motion.p style={{
							textAlign: 'center'
							}}
							
							initial={{
								opacity: 0,
								scale: 0.5
							}}
							animate={{
								opacity: 1,
								scale: 1
							}}
							transition={{ duration: 0.4 }}
						>Step: ↑ ← → ↓</motion.p>
						<motion.p style={{
							textAlign: 'center'
							}}
							
							initial={{
								opacity: 0,
								scale: 0.5
							}}
							animate={{
								opacity: 1,
								scale: 1
							}}
							transition={{ duration: 0.4 }}
						>Pathfinder count: {queue ? queue.length : 0}</motion.p>
						<br/>
						<Maze
							maze={maze}
							startPoint={startPoint}
							exitPoint={exitPoint}
							milestone={milestone}
							path={path}
							queue={queue}
							finish={finish}
							cleanUp={cleanUp}
						/>
						<br/>
						{result && 
							<motion.h1
								style={{
									textAlign: 'center',
									color: result === "Shortest Path doesn't exist" ? 'red' : 'green'
								}}
								initial={{
									opacity: 0,
									scale: 0.5
								}}
								animate={{
									opacity: 1,
									scale: 1
								}}
								transition={{ duration: 0.4 }}
							>{result}</motion.h1>
						}
						
					</>}
					
				</AnimatePresence>
			</AnimateSharedLayout>
			<a href='https://maze-astar.pages.dev/'
				style={{
					position: 'absolute',
					top: '10px',
					right: '10px',
					// color: 'black',
					fontSize: '32px',
				}}
			>AStar</a>
		</div>
	}

	showFile = async (e) => {
		e.preventDefault()
		const reader = new FileReader()
		reader.onload = async (e) => { 
			const text = (e.target.result)
			this.setState({ fileContent: text })
			runMaze(text, this.onStateChange)
		};
		reader.readAsText(e.target.files[0])
	}

	loadFileFromUrl = async (e) => {
		const response = await fetch(this.state.urlPath);
		const text = await response.text();
		this.setState({ fileContent: text })
		runMaze(text, this.onStateChange)
	}
}

export default App;
